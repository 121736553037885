export { default as cssVars } from "./css-vars.json";
export * from "./roles";

export const dateFormat = "YYYY-MM-DD h:mm:ss a";
export const adminDashboardUrl = process.env.REACT_APP_ADMIN_DASHBOARD_URL;
export const tomAppUrl = process.env.REACT_APP_TOM_APP_URL;
export const jeAppUrl = process.env.REACT_APP_JE_APP_URL;
export const rbAppUrl = process.env.REACT_APP_RB_APP_URL;
export const cookiesDomain = process.env.REACT_APP_COOKIES_DOMAIN;
export const listTom = true;
export const listLivePayBenchmarking =
  process.env.REACT_APP_LIST_LIVE_PAY_BENCHMARKING === "true";
export const livePayTool = "LIVE_PAY_BENCHMARKING_TOOL";
